<template>
	<div v-loading="loading" class="container">
		<div class="fx-start">
			<el-select
				v-model="companyIds"
				multiple
				collapse-tags
				:teleported="false"
				@change="onChangeCompany"
			>
				<!-- <el-option
					v-for="item in companyList"
					:key="item.code"
					:label="item.name"
					:disabled="companyIds.length === 1 && companyIds.includes(item.code)"
					:value="item.code"
				></el-option> -->
				<el-checkbox-group v-model="companyIds">
					<el-option
						v-for="item in companyList"
						:key="item.code"
						:label="item.name"
						:value="item.code"
					>
						<el-checkbox :label="item.code" style="pointer-events: none">
							{{ item.name }}
						</el-checkbox>
					</el-option>
				</el-checkbox-group>

				<div class="option-bottom">
					<el-checkbox v-model="checkAll" @change="checkAllCompany">全选</el-checkbox>
					<el-checkbox v-model="checkReverse" @change="checkReverseCompany">反选</el-checkbox>
				</div>
			</el-select>
			<div class="refresh" @click="getRealData">
				<el-icon :size="20" color="#6a7377"><Refresh /></el-icon>
				<span>刷新</span>
			</div>
		</div>
		<div class="panel mt-10">
			<div class="h-wrap">
				<div class="head">核心数据</div>
				<div class="time">
					<img src="./../../assets/img/clock.png" />
					<span>数据更新时间：{{ currTime }}</span>
				</div>
			</div>
			<div class="list">
				<div v-for="(item, index) in coreList" :key="item.title" class="item">
					<div class="h">
						<h5>{{ item.title }}</h5>
						<el-tooltip
							v-if="item.tip"
							class="box-item"
							effect="dark"
							:content="item.tip"
							placement="right"
						>
							<img src="./../../assets/img/question.png" />
						</el-tooltip>
					</div>
					<div class="m">
						<span class="number" @click="jump(index)">{{ item.number }}</span>
						<span class="suffix">{{ item.suffix }}</span>
					</div>
					<div v-if="item.list.length > 0" class="b">
						<div v-for="(b, bIndex) in item.list" :key="bIndex" class="b-i">
							<span>{{ b[0] }}</span>
							<span class="num" :class="{ blue: b[3] === 'blue' }" @click="jump(index, bIndex)">
								{{ b[1] }}
							</span>
							<span>{{ b[2] }}</span>
							<span v-if="b[4]" v-html="b[4]" style="cursor: pointer" @click="subJump(b[4])"></span>
						</div>
					</div>
				</div>
				<div class="gap"></div>
				<div class="gap"></div>
			</div>
		</div>
		<div class="panel mt-10">
			<div class="h-wrap">
				<div class="head">风控预警</div>
				<div class="time">
					<img src="./../../assets/img/clock.png" />
					<span>数据更新时间：{{ currTime }}</span>
				</div>
			</div>
			<div class="list">
				<div v-for="item in riskList" :key="item.title" class="item">
					<div class="h">
						<h5>{{ item.title }}</h5>
						<el-tooltip
							v-if="item.tip"
							class="box-item"
							effect="dark"
							:content="item.tip"
							placement="right"
						>
							<img src="./../../assets/img/question.png" />
						</el-tooltip>
					</div>
					<div class="m">
						<span class="number">{{ item.number }}</span>
						<span class="suffix">{{ item.suffix }}</span>
					</div>
					<div v-if="item.list.length > 0" class="b">
						<div v-for="(b, bIndex) in item.list" :key="bIndex" class="b-i">
							<span>{{ b[0] }}</span>
							<span class="num" :class="{ blue: b[3] === 'blue' }">{{ b[1] }}</span>
							<span>{{ b[2] }}</span>
						</div>
					</div>
				</div>
				<div class="gap"></div>
				<div class="gap"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'ChartReal',
})
</script>
<script setup name="ChartReal">
import { ref, inject } from 'vue'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'
import {
	getCarStatis,
	getAccidentStatis,
	getStockInStatis,
	getMaintainStatis,
	companySelect,
	getRentStatis,
	getRiskRealStatis,
	getRiskViolateStatis,
} from '@/api/chart'
import { useFetch } from '@/utils/hooks.js'

const router = useRouter()
const companyIds = ref([])
const companyList = ref([])
const coreList = ref([])
const riskList = ref([])
const currTime = ref('')
const loading = ref(false)
const $message = inject('$message')
const checkAll = ref(false)
const checkReverse = ref(false)
const { fetchData } = useFetch()

const fetchCompanyList = async () => {
	const { err, data } = await fetchData(companySelect, { isPermission: true })
	if (err) $message.error(err)
	companyIds.value = [data.map(item => item.companyId)[0]]
	companyList.value = data.map(item => ({ name: item.companyName, code: item.companyId }))
	getRealData()
}
const getCoreData = async () => {
	try {
		const [car, rent, accident, maintain, income] = await Promise.all([
			getCarStatis(companyIds.value),
			getRentStatis(companyIds.value),
			getAccidentStatis({ carInfoCompanyIds: companyIds.value }),
			getMaintainStatis({ carInfoCompanyIds: companyIds.value }),
			getStockInStatis(companyIds.value),
		])
		if (car.code === 200) {
			coreList.value[0].number = car.data?.rtoRate
			coreList.value[0].list[0][1] = car.data?.inOperationCount
			coreList.value[0].list[1][1] = car.data?.assetsCount
			// 兼容处理
			coreList.value[0].list[0][4] = `(到期未退<span style="color: red">${car.data?.dueNotReturnCount}</span>台)`

			coreList.value[1].number = car.data?.stockVehicleCount
			coreList.value[1].list[0][1] = car.data?.toBeLetCount
			coreList.value[1].list[1][1] = car.data?.toBeReturnedCount
			coreList.value[1].list[2][1] = car.data?.toBeDeliveredCount
			coreList.value[1].list[3][1] = car.data?.toBePreparedCount
			coreList.value[1].list[4][1] = car.data?.toBeRepairedCount
			coreList.value[1].list[5][1] = car.data?.inMaintenanceCount

			coreList.value[2].number = car.data?.todayDeliveredCount

			coreList.value[3].number = car.data?.todayReturnedCount
			// coreList.value[3].list[0][1] = car.data?.toBeReturned
			coreList.value[3].list[0][1] = car.data?.todayOrderReturnCarCount
		}
		if (rent.code === 200) {
			// coreList.value[4].number = rent.data?.realtimeRentDriver?.rentDriverCount
			// coreList.value[4].list[0][1] = rent.data?.realtimeRentDriver?.completeRate
			// coreList.value[4].list[1][1] = rent.data?.realtimeRentDriver?.oweRentDriverCount

			coreList.value[4].number = rent.data?.realtimeRentAmount?.todayRentAmount
			coreList.value[4].list[0][1] = rent.data?.realtimeRentAmount?.dayRentRate
			coreList.value[4].list[1][1] = rent.data?.realtimeRentAmount?.arrearsAmount

			coreList.value[5].number = rent.data?.realtimeRentAmountMonthly?.monthRentAmount
			coreList.value[5].list[0][1] = rent.data?.realtimeRentAmountMonthly?.monthRentRate
			coreList.value[5].list[1][1] = rent.data?.realtimeRentAmountMonthly?.arrearsAmount

			coreList.value[6].number = rent.data?.realtimeBreachPenaltyMonthly?.breachPenalty // 本月违约金收入[index = 6]:

			coreList.value[13].number = rent.data?.realtimeDriverExpiring?.driverCount // 未来30天将到期司机数

			coreList.value[14].number = rent.data?.realtimeOrderReturnCar?.orderReturnCount // 未来30天预约退车数[index = 14]
		}
		if (accident.code === 200) {
			coreList.value[7].number = accident.data?.count
			coreList.value[7].list[0][1] = accident.data?.noResponsibleCount
			coreList.value[7].list[1][1] = accident.data?.responsibleCount
		}
		if (maintain.code === 200) {
			coreList.value[8].number = maintain.data?.maintainingCost
			coreList.value[8].list[0][1] = maintain.data?.maintainingCount

			coreList.value[9].number = maintain.data?.maintenanceCost
			coreList.value[9].list[0][1] = maintain.data?.maintenanceCount

			coreList.value[10].number = maintain.data?.vulnerablePartsCost
			coreList.value[10].list[0][1] = maintain.data?.vulnerablePartsCount

			coreList.value[12].number = maintain.data?.todayExpenditureAmount
			coreList.value[12].list[0][1] = maintain.data?.currentMonthExpenditureAmount
		}
		if (income.code === 200) {
			coreList.value[11].number = income.data?.todayIncomeAmount
			coreList.value[11].list[0][1] = income.data?.monthTotalIncomeAmount
		}
	} catch (err) {
		console.log('getCoreData err =>', err)
		$message.error(err.message)
	} finally {
		loading.value = false
	}
}
const getRiskData = async () => {
	try {
		const [first, second] = await Promise.all([
			getRiskRealStatis(companyIds.value),
			getRiskViolateStatis(companyIds.value),
		])
		if (first.code === 200) {
			riskList.value[0].number = first.data?.overdueRent
			riskList.value[2].number = first.data?.accident
			riskList.value[3].number = first.data?.mileage
		}
		if (second.code === 200) {
			riskList.value[1].number = second.data?.violateCarNum
			riskList.value[1].list[0][1] = second.data?.violateNum
			riskList.value[1].list[1][1] = second.data?.monthRate
		}
	} catch (err) {
		console.log('getRiskData err =>', err)
		$message.error(err.message)
	} finally {
		loading.value = false
	}
}
const initData = () => {
	coreList.value = [
		{
			title: '实时出租率',
			tip: '营运中的车辆数量 除于 资产车辆数量（资产车辆不包含退出运营）',
			number: '-',
			suffix: '%',
			list: [
				['出租中', '-', '台', 'blue'],
				['资产数', '-', '台'],
			],
		},
		{
			title: '库存车辆数',
			tip: '未出租的车辆数量，包含待出租、待整备和维修',
			number: '-',
			suffix: '台',
			list: [
				// ['可出租', '-', '台', 'blue'],
				// ['待运营', '-', '台'],
				// ['待交车', '-', '台'],
				['待出租', '-', '台', 'red'],
				['待退车', '-', '台', 'red'],
				['待交车', '-', '台', 'red'],
				['待整备', '-', '台', 'red'],
				['待维修', '-', '台', 'red'],
				['维修中', '-', '台', 'red'],
			],
		},
		{
			title: '今日已交车',
			number: '-',
			suffix: '台',
			list: [],
		},
		{
			title: '今日已退车',
			number: '-',
			suffix: '台',
			list: [['预约退车', '-', '台', 'blue']],
		},
		// {
		// 	title: '今日已交租金司机',
		// 	number: '-',
		// 	suffix: '人',
		// 	list: [
		// 		['完成率', '-', '', 'blue'],
		// 		['欠租司机', '-', '人'],
		// 	],
		// },
		{
			title: '今日已收租金',
			number: '-',
			suffix: '万元',
			list: [
				['日收取率', '-', '', 'blue'],
				['欠租', '-', '万元'],
			],
		},
		{
			title: '本月累计收取租金',
			number: '-',
			suffix: '万元',
			list: [
				['月收取率', '-', '', 'blue'],
				['累计欠租', '-', '万元'],
			],
		},
		{
			title: '本月违约金收入',
			number: '-',
			suffix: '万元',
			list: [],
		},
		{
			title: '今日事故出险总数',
			number: '-',
			suffix: '起',
			list: [
				['无责事故', '-', '起', 'blue'],
				['有责事故', '-', '起'],
			],
		},
		{
			title: '今日维修总金额',
			number: '-',
			suffix: '元',
			list: [['今日维修', '-', '台次', 'blue']],
		},
		{
			title: '今日保养总金额',
			number: '-',
			suffix: '元',
			list: [['今日保养', '-', '台次', 'blue']],
		},
		{
			title: '今日易损件更换总金额',
			number: '-',
			suffix: '元',
			list: [['今日易损件更换', '-', '台次', 'blue']],
		},
		{
			title: '今日车辆整备支出金额',
			number: '-',
			suffix: '元',
			list: [['本月整备累计支出', '-', '万元', 'blue']],
		},
		{
			title: '今日车辆整备收入金额',
			number: '-',
			suffix: '元',
			list: [['本月整备累计收入', '-', '万元', 'blue']],
		},
		{
			title: '未来30天将到期司机数',
			number: '-',
			suffix: '人',
			list: [],
		},
		{
			title: '未来30天预约退车数',
			number: '-',
			suffix: '辆',
			list: [],
		},
	]
	riskList.value = [
		{
			title: '欠租3天以上司机数',
			number: '-',
			suffix: '人',
			list: [],
		},
		{
			title: '违章超9分/1000元车辆数',
			number: '-',
			suffix: '辆',
			list: [
				['累计待处理违章数', '-', '个'],
				['本月违章处理率', '-'],
			],
		},
		{
			title: '有责事故超2次司机数',
			number: '-',
			suffix: '人',
			list: [],
		},
		{
			title: '里程超1.1万公里未保养',
			number: '-',
			suffix: '人',
			list: [],
		},
	]
}
const getRealData = () => {
	loading.value = true
	currTime.value = dayjs().format('YYYY-MM-DD HH:mm')
	getCoreData()
	getRiskData()
}
// 调整参数的顺序和页面中图块的顺序一一对应
const jumpParams = [
	// 实时出租率
	{
		main: {
			path: '/vehicle-management',
			query: [['statusList', '5,7']],
		},
		list: [
			{
				path: '/vehicle-management',
				query: [
					// 	['statusList', '7'],
					['isInStock', false],
				],
			},
			{
				path: '/vehicle-management',
				query: [['statusList', 'all']],
			},
		],
	},
	// 库存车辆数
	{
		main: {
			path: '/vehicle-management',
			query: [
				['statusList', '1,2,3,4,5,6'],
				['isInStock', true],
			],
		},
		list: [
			{
				path: '/vehicle-management',
				query: [
					['statusList', '4'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '6'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '5'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '1'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '2'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '3'],
					['isInStock', true],
				],
			},
		],
	},
	// 今日已交车
	{
		main: {
			path: '/vehicle-delivery',
			query: [['deliveryTime', dayjs().format('YYYY-MM-DD')]],
		},
		list: [{ path: '/vehicle-management', query: [['statusList', '5']] }],
	},
	// 今日已退车
	{
		main: {
			path: '/vehicle-check',
			query: [['inspectTime', dayjs().format('YYYY-MM-DD')]],
		},
		list: [
			{
				path: '/vehicle-management',
				query: [
					['statusList', 6],
					['isInStock', true],
				],
			},
		], // TODO:增加预约退车台数
	},
	// 今日已交租金司机
	// {
	// 	main: {
	// 		path: '/driver-rent',
	// 		query: [
	// 			['statementDate', dayjs().format('YYYY-MM-DD')],
	// 			['status', '2'],
	// 		],
	// 	},
	// 	list: [
	// 		{ path: '', query: [] },
	// 		{
	// 			path: '/driver-rent',
	// 			query: [
	// 				['statementDate', dayjs().format('YYYY-MM-DD')],
	// 				['status', '1'],
	// 			],
	// 		},
	// 	],
	// },
	// 今日已收租金
	{
		main: {
			path: '/driver-rent',
			query: [
				['statementDate', dayjs().format('YYYY-MM-DD')],
				['status', [2]],
			],
		},
		list: [
			{ path: '', query: [] },
			{
				path: '/driver-rent',
				query: [
					['statementDate', dayjs().format('YYYY-MM-DD')],
					['status', [1]],
				],
			},
		],
	},
	// 本月累计收取租金
	{
		main: {
			path: '/driver-rent',
			query: [
				[
					'statementDate',
					`${dayjs().date(1).format('YYYY-MM-DD')},${dayjs().format('YYYY-MM-DD')}`,
				],
				['status', [2, 3, 4, 6]],
				['tradeBillType', 1],
			],
		},
		list: [
			{ path: '', query: [] },
			{
				path: '/driver-rent',
				query: [
					['status', [1, 3, 5]],
					['tradeBillType', 1],
					[
						'statementDate',
						`${dayjs('2022-1-1').format('YYYY-MM-DD')},${dayjs()
							.subtract(1, 'day')
							.format('YYYY-MM-DD')}`,
					],
				],
			},
		],
	},
	// 本月违约金收入
	{
		main: {
			path: '',
			query: [],
		},
	},
	// 今日事故出险总数
	{
		main: {
			path: '/incident-management',
			query: [['date', `${dayjs().format('YYYY-MM-DD')}`]],
		},
		list: [
			{
				path: '/incident-management',
				query: [
					['date', `${dayjs().format('YYYY-MM-DD')}`],
					['status', '5'],
				],
			},
			{
				path: '/incident-management',
				query: [
					['date', `${dayjs().format('YYYY-MM-DD')}`],
					['status', '1,2,3,4'],
				],
			},
		],
	},
	// 今日维修总金额
	{
		main: {
			path: '/maintenance-management',
			query: [
				['date', `${dayjs().format('YYYY-MM-DD')}`],
				['status', '1'],
			],
		},
		list: [
			{
				path: '/maintenance-management',
				query: [
					['date', `${dayjs().format('YYYY-MM-DD')}`],
					['status', '1'],
				],
			},
		],
	},
	// 今日保养总金额
	{
		main: {
			path: '/maintenance-management',
			query: [
				['date', `${dayjs().format('YYYY-MM-DD')}`],
				['status', '2'],
			],
		},
		list: [
			{
				path: '/maintenance-management',
				query: [
					['date', `${dayjs().format('YYYY-MM-DD')}`],
					['status', '2'],
				],
			},
		],
	},
	// 今日易损件更换总金额
	{
		main: {
			path: '/maintenance-management',
			query: [
				['date', `${dayjs().format('YYYY-MM-DD')}`],
				['status', '3'],
			],
		},
		list: [
			{
				path: '/maintenance-management',
				query: [
					['date', `${dayjs().format('YYYY-MM-DD')}`],
					['status', '3'],
				],
			},
		],
	},
	// 今日车辆整备收入金额
	{
		main: {
			path: '/vehicle-check',
			query: [['inspectTime', `${dayjs().format('YYYY-MM-DD')}`]],
		},
		list: [
			{
				path: '/vehicle-check',
				query: [
					[
						'inspectTime',
						`${dayjs().startOf('month').format('YYYY-MM-DD')},${dayjs().format('YYYY-MM-DD')}`,
					],
				],
			},
		],
	},
	// 今日车辆整备支出金额
	{
		main: {
			path: '/maintenance-management',
			query: [
				['date', `${dayjs().format('YYYY-MM-DD')}`],
				['status', '1'],
			],
		},
		list: [
			{
				path: '/maintenance-management',
				query: [
					[
						'date',
						`${dayjs().startOf('month').format('YYYY-MM-DD')},${dayjs().format('YYYY-MM-DD')}`,
					],
					['status', '1'],
				],
			},
		],
	},
	// 未来30天将到期司机数
	{
		main: {
			path: '/driver-contract',
			query: [
				[
					'contractEndDate',
					`${dayjs().format('YYYY-MM-DD')},${dayjs().add(30, 'day').format('YYYY-MM-DD')}`,
				],
				['status', '-1,1'],
			],
		},
	},
	// 未来30天预约退车数
	{
		main: {
			path: '/reserve-car',
			query: [
				[
					'reserveDate',
					`${dayjs().add(1, 'day').format('YYYY-MM-DD')},${dayjs()
						.add(30, 'day')
						.format('YYYY-MM-DD')}`,
				],
			],
		},
	},
]
const jump = (i, j) => {
	let path,
		query = {}
	console.log('jump', i, j, jumpParams)
	if (j !== undefined) {
		path = jumpParams[i].list[j].path
		for (const [k, v] of jumpParams[i].list[j].query) {
			query = {
				...query,
				[k]: v,
			}
		}
	} else {
		path = jumpParams[i].main.path
		for (const [k, v] of jumpParams[i].main?.query) {
			query = {
				...query,
				[k]: v,
			}
		}
	}
	// 当所选公司不是所有公司列表的时候需要显示传入公司id
	if (companyIds.value.length !== companyList.value.length) {
		query.companyIds = companyIds.value.join(',')
	}
	if (!path) return
	router.push({ path, query })
}
//
const subJump = html => {
	let path = ''
	let query = {}
	if (html.indexOf('到期未退') !== -1) {
		router.push({
			path: 'driver-contract',
			query: {
				status: 4,
				isValidateCar: false,
			},
		})
		return
	}
	// 当所选公司不是所有公司列表的时候需要显示传入公司id
	if (companyIds.value.length !== companyList.value.length) {
		query.companyIds = companyIds.value.join(',')
	}
	router.push({ path, query })
}
const onChangeCompany = val => {
	if (val.length === 0) {
		$message.warning('请至少选择一个公司')
		return
	}
	if (val.length === companyList.value.length) {
		checkAll.value = true
		checkReverse.value = false
	} else {
		checkAll.value = false
	}
	getRealData()
}
const checkAllCompany = () => {
	// 全选情况下, 再次点击, 默认勾选第1个公司
	if (checkAll.value === false) {
		companyIds.value = [companyList.value[0].code]
	} else {
		companyIds.value = companyList.value.map(item => item.code)
	}
	checkReverse.value = false
	getRealData()
}
const checkReverseCompany = () => {
	// 全选情况下, 点击反选, 默认勾选第1个公司
	if (checkAll.value === true) {
		companyIds.value = [companyList.value[0].code]
	} else {
		let curSelects = companyIds.value
		companyIds.value = companyList.value
			.filter(item => !curSelects.includes(item.code))
			.map(item => item.code)
	}
	checkAll.value = false
	getRealData()
}
initData()
fetchCompanyList()
</script>

<style lang="less" scoped>
.list {
	.flexible(row, flex-start, space-between);
	flex-wrap: wrap;
	padding-top: 16px;
}
.gap {
	flex: 320px 1 1;
	margin: 0 15px 0 0;
}
.item {
	flex: 320px 1 1;
	height: 136px;
	padding: 16px 0 10px 15px;
	box-sizing: border-box;
	background-color: #f9fafb;
	margin: 0 15px 15px 0;
	.h {
		.flexible(row, center, flex-start);
		h5 {
			font-size: 16px;
			line-height: 16px;
			font-weight: 600;
			color: #242b30;
			margin: 0 10px 0 0;
		}
	}
	.m {
		.flexible(row, flex-end, flex-start);
		padding: 20px 0 15px;
		.number {
			color: #ff9423;
			font-weight: 700;
			font-size: 30px;
			line-height: 30px;
			font-family: DIN-Alternate, PingFang SC, sans-serif;
		}
		.suffix {
			font-size: 16px;
			font-weight: 600;
			color: #242b30;
			margin-left: 4px;
			margin-bottom: 1px;
		}
	}
	.b {
		.flexible(row, center, flex-start);
		flex-wrap: wrap;
		color: #6a7377;
		font-size: 12px;
		line-height: 12px;
		font-weight: 400;
		.num {
			padding: 0 3px;
			color: #ff5858;
			font-weight: 500;
			&.blue {
				color: #2eb3ff;
			}
		}
		.b-i {
			flex: 1;
			min-width: 32%;
			margin-bottom: 6px;
			white-space: nowrap;
		}
	}
}
.h-wrap {
	.flexible(row, center, flex-start);
	.svg-icon {
		font-size: 13px;
		color: #666;
		margin: 0 8px 0 10px;
	}
	.time {
		font-size: 12px;
		color: #6a7377;
		img {
			vertical-align: -2px;
			margin: 0 4px 0 10px;
			width: 12px;
			height: 12px;
		}
	}
}
.head {
	font-size: 18px;
	line-height: 18px;
	font-weight: 600;
	color: #242b30;
}
.mt-10 {
	margin-top: 10px;
}
.fx-start {
	.flexible(row, center, flex-start);
	.refresh {
		.flexible(row, center, space-between);
		width: 90px;
		height: 38px;
		background: #ffffff;
		margin-left: 10px;
		padding: 0 16px;
		box-sizing: border-box;
		border-radius: 4px;
		cursor: pointer;
		span {
			color: #606266;
			font-size: 14px;
			padding-left: 8px;
		}
	}
}
.option-bottom {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 32px;
	margin: 0 16px;
}
.switch-button {
	cursor: pointer;
	color: #409eff;
	margin-right: 17px;
	font-size: 14px;
	font-weight: 600;
}
.el-select {
	width: 300px;
	:deep(.el-input) {
		height: 38px;
		line-height: 38px;
	}
	:deep(.el-input__wrapper) {
		box-shadow: none;
	}
	:deep(.el-input.is-focus .el-input__wrapper) {
		box-shadow: none !important;
	}
	:deep(.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after) {
		display: none !important;
	}
}
.panel {
	background-color: #fff;
	padding: 20px 0 0 15px;
}
@media screen and (min-width: 1284px) {
	.item,
	.gap {
		flex: 340px 1 1;
	}
}
</style>
